<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#68D389"
      size="128"
      is-full-screen
    />
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-if="!ServerError" wrap justify-center>
      <v-flex px-4 xs12 sm6 md4 lg4 xl4 text-left>
        <span class="itemHeading">Guest List</span>
      </v-flex>
      <v-flex xs12 sm6 md3 lg3 xl3>
        <v-text-field
          v-model="keyword"
          append-icon="mdi-magnify"
          solo
          label="Search"
          color="#68d389"
          single-line
          hide-details
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 md5 lg5 xl5>
        <download-excel :data="json_data" :fields="json_fields">
          <span
            style="
              font-size: 16px;
              font-family: poppinssemibold;
              color: #68d389;
              cursor: pointer;
            "
          >
            Download Excel</span
          >
        </download-excel>
      </v-flex>
      <v-flex xs12 sm6 align-self-center pa-4 text-left>
        <v-layout wrap>
          <v-flex x12 sm6 align-self-center>
            <span style=" font-family: poppinssemibold;">Rows Per Page</span>
          </v-flex>
          <v-flex x12 sm6 lg3 align-self-center>
            <v-select
              hide-details
              dense
              v-model="count"
              :items="[20, 50, 100]"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-spacer></v-spacer>

      <v-flex xs12>
        <v-spacer></v-spacer>
      </v-flex>
      <v-layout wrap justify-start>
        <template v-for="(item, i) in guest">
          <v-flex pa-5 xs12 xl11 :key="item._id" pr-lg-6 pr-xl-0>
            <v-layout wrap>
              <v-flex xs12 sm12 md12 lg6>
                <v-layout wrap justify-start>
                  <v-flex lg12 text-left>
                    <span
                      style="font-size: 18px; color: #000000"
                      class="text-capitalize"
                    >
                      {{ item.name }}
                    </span>
                  </v-flex>
                  <v-flex xs12 align-self-end>
                    <v-layout wrap justify-start>
                      <v-flex xs12 sm8 md6 lg6 xl6>
                        <v-layout wrap>
                          <v-flex text-left xs10>
                            <span style="font-size: 16px">
                              {{ item.email }}
                            </span>
                          </v-flex>
                        </v-layout>
                        <v-layout wrap>
                          <v-flex text-left xs10 v-if="item.phoneno">
                            <span class="getValue">
                              {{ item.phoneno }}
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      
                      <v-flex xs12 sm6 md3 lg3 xl3>
                        <v-layout wrap>
                          <v-flex text-left xs12>
                            <span class="getText"> Gender </span>
                          </v-flex>
                          <v-flex text-left xs12 v-if="item.gender">
                            <span class="getValue">
                              {{ item.gender }}
                            </span>
                          </v-flex>
                          <v-flex text-left xs12 v-else>
                            <span class="getValue"> Nil </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm6 md3 lg3 xl3>
                        <v-layout wrap fill-height>
                          <v-flex xs12 sm12 text-left>
                            <span class="getText"> Guest Type </span>
                          </v-flex>
                          <v-flex
                            xs12
                            sm12
                            text-left
                            align-self-start
                            v-if="item.guestType"
                          >
                            <span class="getValue">
                              {{ item.guestType }}
                            </span>
                          </v-flex>
                          <v-flex xs12 sm12 text-left align-self-start v-else>
                            <span class="getValue"> Nil </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>

                      <v-flex xs12 sm6 md3 lg3 xl3 v-if="item.foodPreference">
                        <v-layout wrap>
                          <v-flex text-left xs12>
                            <span class="getText"> Food Preference </span>
                          </v-flex>
                          <v-flex text-left xs12 v-if="item.foodPreference.length>0">
                            <span class="getValue" v-for="(food,f) in item.foodPreference" :key="f">
                              {{ food }}, <br>
                            </span>
                          </v-flex>
                          <v-flex text-left xs12 v-else>
                            <span class="getValue"> Nil </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex text-left pt-6 xs12 md12 lg6>
                <v-layout wrap justify-space-between>
                  <v-flex xs12 sm6 md4 lg4 xl4>
                    <v-layout wrap fill-height>
                      <v-flex xs12 sm12>
                        <span class="getText"> Nationality </span>
                      </v-flex>
                      <v-flex
                        xs12
                        sm12
                        text-left
                        align-self-start
                        v-if="item.nationality"
                      >
                        <span class="getValue">
                          {{ item.nationality }}
                        </span>
                      </v-flex>
                      <v-flex xs12 sm12 text-left align-self-start v-else>
                        <span class="getValue"> Nil </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg4 xl4>
                    <v-layout wrap fill-height>
                      <v-flex xs12 sm12>
                        <span class="getText"> State </span>
                      </v-flex>
                      <v-flex
                        xs12
                        sm12
                        text-left
                        align-self-start
                        v-if="item.state"
                      >
                        <span class="getValue">
                          {{ item.state }}
                        </span>
                      </v-flex>
                      <v-flex xs12 sm12 text-left align-self-start v-else>
                        <span class="getValue"> Nil </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 lg4 xl4>
                    <v-dialog v-model="item.delete" max-width="600px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          tile
                          block
                          small
                          outlined
                          color="red"
                          style="text-transform: none"
                          v-on="on"
                          v-bind="attrs"
                        >
                          <span
                            style="
                              color: #000;
                              font-family: poppinsregular;
                              font-size: 14px;
                            "
                          >
                            Delete
                          </span>
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          >Are you sure you want to delete this
                          Guest?</v-card-title
                        >
                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="item.delete = false"
                            >Cancel</v-btn
                          >
                          <v-btn
                            color="blue darken-1"
                            text
                            @click="deleteItem(item)"
                            >OK</v-btn
                          >
                          <v-spacer></v-spacer>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-flex>
                  <v-flex
                    v-if="item.nationality !== 'Indian'"
                    xs12
                    sm6
                    md12
                    lg4
                    xl4
                  >
                    <v-layout wrap fill-height>
                      <v-flex xs12 sm12>
                        <span class="getText"> Id Proof </span>
                      </v-flex>
                      <v-flex
                        xs12
                        sm12
                        text-left
                        align-self-start
                        v-if="item.idNumber"
                      >
                        <span class="getValue">
                          {{ item.idNumber }}
                        </span>
                      </v-flex>
                      <v-flex xs12 sm12 text-left align-self-start v-else>
                        <span class="getValue"> Nil </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 xl11 :key="i">
            <v-divider></v-divider>
          </v-flex>
        </template>
      </v-layout>
    </v-layout>
    <div class="pt-2" v-if="pages > 1">
      <v-pagination
        :length="pages"
        :total-visible="7"
        v-model="currentPage"
        color="#68d389"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      search: "",
      currentPage: 1,
      name: "",
      pages: 0,
      count: 20,
      totalData: 0,
      guest: [],
      keyword: "",
      deliverystatus: null,
      totalRows: 0,
      ServerError: false,
      json_fields: {
        Name: "name",
        PhoneNumber: "phoneno",
        Email: "email",
        // DateofBirth: "dob",
        Gender: "gender",
        GuestType: "guestType",
        Nationality: "nationality",
        State: "state",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
    };
  },
  watch: {
      count() {
      this.currentPage = 1;
      this.getData();
    },
    keyword() {
      this.currentPage = 1;
      this.getData();
    },
    currentPage() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      if (this.currentPage && this.count) {
        axios({
          url: "/guest/getlist",
          method: "GET",
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            count: this.count,
            page: this.currentPage,
            keyword: this.keyword,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              if(response.data.data) {
                for (var i = 0; i < response.data.data.length; i++) {
                response.data.data[i].dob = this.formatDate(
                  response.data.data[i].dob
                );
              }
              }
              this.guest = response.data.data;
              this.json_data = this.guest;

              this.name = this.guest.name;
              // this.getExcel();
              this.pages = response.data.pages;
              this.totalData = response.data.count;
              this.appLoading = false;
            } else {
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    getExcel() {
      this.appLoading = true;
      axios({
        url: "/guest/getlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          // count: this.count,
          // page: this.currentPage,
          keyword: this.keyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // for (var i = 0; i < response.data.data.length; i++) {
            //   response.data.data[i].dob = this.formatDate(
            //     response.data.data[i].dob
            //   );
            // }
            this.json_data = response.data.data;
            this.name = this.guest.name;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/guest/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>
